import {Routes, Route, Link, useNavigate, useLocation} from "react-router-dom";

import "animate.css";

import GlobalStyle from "./style/style";
import CommonStyle from "./style/commonStyle";
import AntdOverride from "./style/antdOverride";
import React from "react";
import {ConnectProvider, useConnect} from "./api/contracts";
import FireDAOHeader from "./component/FireDAOHeader/FireDAOHeader";
import NFTAdmin from "./view/MintNFTAdmin/NFTAdmin";
import L23NFTAdmin from "./view/MintNFTAdmin/L23NFTAdmin";

import MintNFT from "./view/MintNFT";
import FireDAOFooter from "./component/FireDAOFooter/FireDAOFooter";
import MyNFT from "./view/MyNFT"
function App() {
    const history = useNavigate();
    const location = useLocation()
    console.log(location.pathname)
    return (

        <ConnectProvider>
            <CommonStyle/>
            <GlobalStyle/>
            <AntdOverride/>
            <div style={{display:"flex",flexDirection:"column"}}>
                <FireDAOHeader/>

                <div className="content">

                    <Routes>
                        <Route path="/" element={<MintNFT/>}/>
                        <Route path="/NFTAdmin" element={<NFTAdmin/>}/>
                        <Route path="/Admin" element={<L23NFTAdmin/>}/>
                        <Route path="/MyNFT" element={<MyNFT/>}/>

                    </Routes>
                    <FireDAOFooter/>
                </div>
            </div>

        </ConnectProvider>

    )
}

export default App;
