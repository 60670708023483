import erc20Abi from "../abi/erc20.json";
import MintSOL from "../abi/fireOGNFT.json";

let CONTRACTS
CONTRACTS = {
    usdt: {address: "0x55d398326f99059fF775485246999027B3197955", abi: erc20Abi},
    MintSOL: {address: "0x26dd16d5b9708032872a7cd10c100b0fce4e79a9", abi: MintSOL},

};
// CONTRACTS = {
//     usdt: {address: "0xC89C114d3A66cbbBbe7B1F630FD1A36bf7Fd992f", abi: erc20Abi},
//     MintSOL: {address: "0x35fb59F95e51a3b6cbf88AB7a3996AEe391B3835", abi: MintSOL},
//
// };
export default CONTRACTS
