import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../api/contracts";
import BigNumber from "bignumber.js"
import {
    Button,
    message,
    AutoComplete,
    Switch,
    Select,
    Form,
    Empty,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../utils/contractUtil"

import {useLocation, useNavigate} from "react-router-dom";
import judgeStatus from "../../utils/judgeStatus";

import sc from "../../imgs/sc.png"
import cut from "../../imgs/remove.png"
import add from "../../imgs/add.png"
import NFTAdminStyle from './NFTAdminStyle';


const NFTAdmin = (props) => {
    let {state, dispatch} = useConnect();
    const location = useLocation()



    const [isDelMolOpen, setDelOpen] = useState(false)

    const [curDelAddr, setCurDelAddr] = useState()

    const [curLevel, setCurLevel] = useState()
    const [isAddMolOpen, setAddOpen] = useState(false)
    const [isAddL2MolOpen, setAddL2Open] = useState(false)
    const [isAddL3MolOpen, setAddL3Open] = useState(false)

    const history = useNavigate();
    const [form] = Form.useForm();



    const [whitelistArr, setWhiteListArr] = useState([])

    const [inWhitelistArr, setInWhitelistArr] = useState([])

    const [adminArr, setAdminArr] = useState([])
    const [L3owner, setL3Owner] = useState()

    const [L2owner, setL2Owner] = useState()
    const [owner, setOwner] = useState("")
    const [initAmount, setInitAmount] = useState()
    const [mintedArr, setMintedArr] = useState([])
    const [recommendRatio, setRecommendRatio] = useState()

    const [addWhiteArr, setAddWArr] = useState([{}])
    const [addL2Arr, setL2WArr] = useState([{}])
    const [addL3Arr, setL3WArr] = useState([{}])
    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }


    const addOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.push({})
        setL2WArr(addWhiteArrT)
    }
    const removeOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.shift()
        setL2WArr(addWhiteArrT)
    }

    const addOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.push({})
        setL3WArr(addWhiteArrT)
    }
    const removeOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.shift()
        setL3WArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const setrecommendRatio= async () => {

        await handleDealMethod( "setrecommendRatio", [form.getFieldValue().recommendRatio*100])
        getRecommendRatio()
    }
    const setReceiver= async () => {

        await handleDealMethod( "setReceiver", [form.getFieldValue().Receiver])
    }
    const setadminL2AddWlAmount= async () => {

        await handleDealMethod( "setadminL2AddWlAmount", [form.getFieldValue().L2Amount])
        getL2AMount()
    }
    const setadminL3AddWlAmount= async () => {
        await handleDealMethod( "setadminL3AddWlAmount", [form.getFieldValue().L3Amount])
        getL3AMount()
    }
    const transferOwnership = async () => {

        await handleDealMethod( "transferOwnership", [form.getFieldValue().address])
        getOwner()
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }
    const getL2AMount = async () => {
        const Admin = await handleViewMethod("adminL2AddWlAmount", [])
        setL2Owner(Admin)
    }
    const getRecommendRatio= async () => {
        const res = await handleViewMethod("recommendRatio", [])
        setRecommendRatio(res)
    }
    const getL3AMount = async () => {
        const Admin = await handleViewMethod("adminL3AddWlAmount", [])
        setL3Owner(Admin)
    }
    const getAdmin = async () => {

        let adminArr = []
        for(let i=0;i<7;i++){
            const Admin = await handleViewMethod("owner", [])
            adminArr.push(Admin)
        }
        setAdminArr(adminArr)
    }
    const getInitAmount = async () => {
        let initamount = 0, amount = 0
        initamount = await handleViewMethod( "initAmount", [])
        amount = await handleViewMethod( "totalMint", [])
        setInitAmount(initamount)
        setMintedArr(amount)

    }
    const getWiteList = async () => {
        const Admin1 = await handleViewMethod("adminLevel2", [state.account])
        const Admin2 = await handleViewMethod("adminLevel3", [state.account])
        let tempArr = [], whiteArr = []
        if(Admin1){
            whiteArr = await handleViewMethod("getL2WLUsers", [state.account])
            tempArr = whiteArr
        }
        if(Admin2){
            whiteArr = await handleViewMethod("getL3WLUsers", [state.account])
            tempArr = whiteArr
        }
        console.log(Admin1,Admin2,tempArr)
        setWhiteListArr(whiteArr)
        setInWhitelistArr(tempArr)


    }



    const handleSetInitAmount = async () => {
        await handleDealMethod("setInitAmount", [form.getFieldValue().InitAmount])
        getInitAmount()
    }

    const addWhiteListUser = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "addWhiteListUser", [arr])
        getWiteList()
    }



    const setAdminLevel2 = async () => {
        let arr = []
        for (let i = 0; i < addL2Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "setAdminLevel2", [arr,true])
        // getWiteList()
    }

    const setAdminLevel3= async () => {
        let arr = []
        for (let i = 0; i < addL3Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "setAdminLevel3", [arr,true])
        // getWiteList()
    }

    const removeFromWhiteList = async () => {
        await handleDealMethod("removeFromWhiteList", [[curDelAddr]])
        getWiteList()
    }

    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getOwner()
        getAdmin()
        getInitAmount()
        getWiteList()
        getL2AMount()
        getL3AMount()
        getRecommendRatio()

    }, [state.account])


    return (

        <NFTAdminStyle>
            <div className="part1">


                <Modal className="model-dialogdel" title="Delete" open={isDelMolOpen}
                       onOk={() => {
                           removeFromWhiteList()
                       }}
                       onCancel={() => {
                           setDelOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelAddr}
                    </div>
                </Modal>


                <div className='panel-box'>
                    <div className='panel-container1'>
                        <Modal className="model-dialogadd" title="Add" open={isAddMolOpen}
                               onOk={() => {
                                   addWhiteListUser()
                               }}
                               onCancel={() => {
                                   setAddOpen(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneWhite()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneWhite()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <Modal className="model-dialogadd" title="Add" open={isAddL2MolOpen}
                               onOk={() => {
                                   setAdminLevel2()
                               }}
                               onCancel={() => {
                                   setAddL2Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL2Arr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL2()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL2()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>



                        <Modal className="model-dialogadd" title="Add" open={isAddL3MolOpen}
                               onOk={() => {
                                   setAdminLevel3()
                               }}
                               onCancel={() => {
                                   setAddL3Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL3Arr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL3()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL3()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <div>
                            <div className='panel-title'>
                                Set NFT Whitelist
                            </div>

                            <div className='superdao-list-box white-list-box'>
                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                    <div className='col'>
                                        Delete
                                    </div>
                                </div>
                                {whitelistArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display:"flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>
                                        <div className="col sc1">
                                            <img src={sc} className="sc" id='scc' style={{cursor:"pointer"}} onClick={() => {
                                                setDelOpen(true)
                                                setCurDelAddr(item)
                                            }}/>
                                        </div>

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddOpen(true)
                                    }}>Add
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </NFTAdminStyle>
    )
}
export default NFTAdmin;