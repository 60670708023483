import React, {useEffect, useRef, useState} from 'react';
import {useConnect} from "../../api/contracts";
import BigNumber from "bignumber.js"
import {
    Button,
    message,
    AutoComplete,
    Switch,
    Select,
    Form,
    Empty,
    Pagination, Input, Modal
} from 'antd';

import {getContractByName, getContractByContract} from "../../api/connectContract";
import {dealMethod, dealPayMethod, viewMethod} from "../../utils/contractUtil"

import {useLocation, useNavigate} from "react-router-dom";
import judgeStatus from "../../utils/judgeStatus";

import sc from "../../imgs/sc.png"
import cut from "../../imgs/remove.png"
import add from "../../imgs/add.png"
import NFTAdminStyle from './NFTAdminStyle';


const NFTAdmin = (props) => {
    let {state, dispatch} = useConnect();
    const location = useLocation()



    const [isDelMolOpen, setDelOpen] = useState(false)

    const [curDelAddr, setCurDelAddr] = useState()

    const [curLevel, setCurLevel] = useState()
    const [isAddMolOpen, setAddOpen] = useState(false)
    const [isAddL2MolOpen, setAddL2Open] = useState(false)
    const [isAddL3MolOpen, setAddL3Open] = useState(false)

    const history = useNavigate();
    const [form] = Form.useForm();


    const [L1whitelistArr, setL1WhiteListArr] = useState([])

    const [whitelistArr, setWhiteListArr] = useState([])

    const [inWhitelistArr, setInWhitelistArr] = useState([])

    const [adminArr, setAdminArr] = useState([])
    const [L3owner, setL3Owner] = useState()
    const [receiver, setReiver] = useState()
    const [mintStatus, setMintStatus] = useState()

    const [baseURI, setBaseURIData] = useState()

    const [L2owner, setL2Owner] = useState()
    const [owner, setOwner] = useState("")
    const [initAmount, setInitAmount] = useState()
    const [mintedArr, setMintedArr] = useState([])
    const [recommendRatio, setRecommendRatio] = useState()

    const [recommendRatioA, setRecommendRatioA] = useState()

    const [whiteListCanMintAmount, setwhiteListCanMintAmount] = useState()
    const [l2whiteListCanMintAmount, setl2whiteListCanMintAmount] = useState()

    const [l3whiteListCanMintAmount, setl3whiteListCanMintAmount] = useState()
    const [addWhiteArr, setAddWArr] = useState([{}])
    const [addL2Arr, setL2WArr] = useState([{}])
    const [addL3Arr, setL3WArr] = useState([{}])

    const [L2AdminArr, setL2AdminArr] = useState([])
    const [L3AdminArr, setL3AdminArr] = useState([])

    const addOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.push({})
        setAddWArr(addWhiteArrT)
    }
    const removeOneWhite = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addWhiteArr))
        addWhiteArrT.shift()
        setAddWArr(addWhiteArrT)
    }


    const addOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.push({})
        setL2WArr(addWhiteArrT)
    }
    const removeOneL2 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL2Arr))
        addWhiteArrT.shift()
        setL2WArr(addWhiteArrT)
    }

    const addOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.push({})
        setL3WArr(addWhiteArrT)
    }
    const removeOneL3 = async () => {
        let addWhiteArrT = JSON.parse(JSON.stringify(addL3Arr))
        addWhiteArrT.shift()
        setL3WArr(addWhiteArrT)
    }
    const handleViewMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        return await viewMethod(contractTemp, state.account, name, params)
    }
    const handleDealMethod = async (name, params) => {
        let contractTemp = await getContractByName("MintSOL", state.api,)
        if (!contractTemp) {
            message.warn("Please connect", 5)
        }
        await dealMethod(contractTemp, state.account, name, params)
    }
    const setrecommendRatioA= async () => {

        await handleDealMethod( "setrecommendRatioA", [form.getFieldValue().recommendRatioA*100])
        getRecommendRatioA()
    }
    const setrecommendRatio= async () => {

        await handleDealMethod( "setrecommendRatio", [form.getFieldValue().recommendRatio*100])
        getRecommendRatio()
    }
    const setmintStatus= async () => {

        await handleDealMethod( "setmintStatus", [])
        getMintStatus()
    }
    const setReceiver= async () => {

        await handleDealMethod( "setReceiver", [form.getFieldValue().Receiver])
        getReceiver()
    }
    const setBaseURI = async () => {

        await handleDealMethod( "setBaseURI", [form.getFieldValue().BaseURI])
        getBaseUrl()
    }
    const setadminL2AddWlAmount= async () => {

        await handleDealMethod( "setadminL2AddWlAmount", [form.getFieldValue().L2Amount])
        getL2AMount()
    }
    const setadminL3AddWlAmount= async () => {
        await handleDealMethod( "setadminL3AddWlAmount", [form.getFieldValue().L3Amount])
        getL3AMount()
    }
    const transferOwnership = async () => {

        await handleDealMethod( "transferOwnership", [form.getFieldValue().address])
        getOwner()
    }
    const getOwner = async () => {
        const Admin = await handleViewMethod("owner", [])
        setOwner(Admin)
    }
    const getBaseUrl= async () => {
        const res = await handleViewMethod("baseURI", [])
        setBaseURIData(res)
    }
    const getMintStatus = async () => {
        const res = await handleViewMethod("mintStatus", [])
        setMintStatus(res)
    }
    const getReceiver = async () => {
        const res = await handleViewMethod("receiver", [])
        setReiver(res)
    }
    const getL2AMount = async () => {
        const Admin = await handleViewMethod("adminL2AddWlAmount", [])
        setL2Owner(Admin)
    }
    const getRecommendRatioA= async () => {
        const res = await handleViewMethod("recommendRatioA", [])
        setRecommendRatioA(res)
    }
    const getRecommendRatio= async () => {
        const res = await handleViewMethod("recommendRatio", [])
        setRecommendRatio(res)
    }
    const getL3List = async () => {
        const res = await handleViewMethod("getL3List", [])
        setL3AdminArr(res)
    }
    const getL2List = async () => {
        const res = await handleViewMethod("getL2List", [])
        setL2AdminArr(res)
    }
    const getWhiteListCanMintAmount  = async () => {
        const res = await handleViewMethod("whiteListCanMintAmount", [])
        setwhiteListCanMintAmount(res)
    }
    const getl2WhiteListCanMintAmount  = async () => {
        const res = await handleViewMethod("l2whiteListCanMintAmount", [])
        setl2whiteListCanMintAmount(res)
    }
    const getl3WhiteListCanMintAmount  = async () => {
        const res = await handleViewMethod("l3whiteListCanMintAmount", [])
        setl3whiteListCanMintAmount(res)
    }
    const getL3AMount = async () => {
        const Admin = await handleViewMethod("adminL3AddWlAmount", [])
        setL3Owner(Admin)
    }
    const getAdmin = async () => {

        let adminArr = []
        for(let i=0;i<7;i++){
            const Admin = await handleViewMethod("owner", [])
            adminArr.push(Admin)
        }
        setAdminArr(adminArr)
    }
    const getInitAmount = async () => {
        let initamount = 0, amount = 0
        initamount = await handleViewMethod( "initAmount", [])
         amount = await handleViewMethod( "totalMint", [])
        setInitAmount(initamount)
        setMintedArr(amount)

    }
    const getWiteList = async () => {
        let tempArr = [], whiteArr = [],whiteArr1=[]
        for (let i = 0; i < 7; i++) {
            whiteArr = await handleViewMethod("getWiteList", [])
            whiteArr1 = await handleViewMethod("getL1WLUsers", [state.account])

            const inW1 = whiteArr.some(item => {
                return item.toLowerCase() == state.account.toLowerCase()
            })
            tempArr.push(inW1)

        }
        setWhiteListArr(whiteArr)
        setInWhitelistArr(tempArr)
        setL1WhiteListArr(whiteArr1)

    }


    const handlesetwhiteListCanMintAmount = async () => {
        await handleDealMethod("setwhiteListCanMintAmount", [form.getFieldValue().whiteListCanMintAmount])
        getWhiteListCanMintAmount()
    }
    const handleSetInitAmount = async () => {
        await handleDealMethod("setInitAmount", [form.getFieldValue().InitAmount])
        getInitAmount()
    }

    const addWhiteListUser = async () => {
        let arr = []
        for (let i = 0; i < addWhiteArr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "addWhiteListUser", [arr])
        getWiteList()
    }



    const setAdminLevel2 = async () => {
        let arr = []
        for (let i = 0; i < addL2Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "setAdminLevel2", [arr,true])
       getL2List()
    }

    const setAdminLevel3= async () => {
        let arr = []
        for (let i = 0; i < addL3Arr.length; i++) {
            arr.push(form.getFieldValue()["address" + i])
        }
        await handleDealMethod( "setAdminLevel3", [arr,true])
        getL3List()
    }

    const removeFromWhiteList = async () => {
        await handleDealMethod("removeFromWhiteList", [[curDelAddr]])
        getWiteList()
    }

    useEffect(async () => {

        let judgeRes = await judgeStatus(state)
        if (!judgeRes) {
            return
        }
        getMintStatus()

        getOwner()
        getAdmin()
        getInitAmount()
        getWiteList()
        getL2AMount()
        getL3AMount()
        getRecommendRatio()
        getReceiver()
        getBaseUrl()
        getWhiteListCanMintAmount()
        // getl2WhiteListCanMintAmount()
        // getl3WhiteListCanMintAmount()
        getL3List()
        getL2List()
        getRecommendRatioA()

    }, [state.account])


    return (

        <NFTAdminStyle>
            <div className="part1">
                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                            Transfer Administrator
                        </div>
                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                label="Administrator Address"
                            >
                                {owner}
                            </Form.Item>
                            <Form.Item
                                name="address"
                                label="New Address"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                transferOwnership()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                </div>

                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                            Set NFT Amounts
                        </div>

                        <Form form={form} name="control-hooks" className="form">
                            <Form.Item
                                label="Amounts"
                            >
                                <span>
                                    {initAmount}
                                </span>
                            </Form.Item>
                            <Form.Item
                                name="InitAmount"
                                label="New Amounts"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                handleSetInitAmount()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>


                </div>
                <div className="panel-box">
                    <div className="panel-container">
                        <div className='panel-title'>
                             whiteList Can MintAmount(  {whiteListCanMintAmount})
                        </div>



                        {/*<div className='panel-title'>*/}
                        {/*     L2 whiteList Can MintAmount(  {l2whiteListCanMintAmount})*/}
                        {/*</div>*/}


                        {/*<div className='panel-title'>*/}
                        {/*     L3 whiteList Can MintAmount         (  {l3whiteListCanMintAmount})*/}
                        {/*</div>*/}

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="whiteListCanMintAmount"
                                label="New Amounts"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            {/*<Form.Item*/}
                            {/*    name="whiteListCanMintAmount2"*/}
                            {/*    label="New Amounts"*/}
                            {/*    validateTrigger="onBlur"*/}
                            {/*    validateFirst={true}*/}
                            {/*>*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item*/}
                            {/*    name="whiteListCanMintAmount3"*/}
                            {/*    label="New Amounts"*/}
                            {/*    validateTrigger="onBlur"*/}
                            {/*    validateFirst={true}*/}
                            {/*>*/}
                            {/*    <Input/>*/}
                            {/*</Form.Item>*/}
                            <Button type="primary" className="go-btn" onClick={() => {
                                handlesetwhiteListCanMintAmount()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                </div>

                <Modal className="model-dialogdel" title="Delete" open={isDelMolOpen}
                       onOk={() => {
                           removeFromWhiteList()
                       }}
                       onCancel={() => {
                           setDelOpen(false)
                       }}>
                    <p>
                        Wallet Address
                    </p>
                    <div className="value">
                        {curDelAddr}
                    </div>
                </Modal>


                <div className='panel-box'>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setmintStatus({mintStatus?"open":"close"})
                        </div>
                        <Form form={form} name="control-hooks" className="form">

                            <Button type="primary" className="go-btn" onClick={() => {
                                setmintStatus()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setReceiver({receiver})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="Receiver"
                                label="Address"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setReceiver()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className="panel-container">
                        <div className='panel-title'>
                            setBaseURI({baseURI})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="BaseURI"
                                label="BaseURI"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setBaseURI()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                    <div className="panel-container">
                        <div className='panel-title'>
                            setrecommendRatio L2({recommendRatio/100})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatio"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatio()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>

                    <div className="panel-container">
                        <div className='panel-title'>
                            setrecommendRatio L3({recommendRatioA/100})
                        </div>

                        <Form form={form} name="control-hooks" className="form">

                            <Form.Item
                                name="recommendRatioA"
                                label="recommendRatio"
                                validateTrigger="onBlur"
                                validateFirst={true}
                            >
                                <Input/>
                            </Form.Item>
                            <Button type="primary" className="go-btn" onClick={() => {
                                setrecommendRatioA()
                            }}>
                                Confirm
                            </Button>
                        </Form>
                    </div>
                    <div className='panel-container1'>
                        <Modal className="model-dialogadd" title="Add" open={isAddMolOpen}
                               onOk={() => {
                                   addWhiteListUser()
                               }}
                               onCancel={() => {
                                   setAddOpen(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addWhiteArr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneWhite()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneWhite()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <Modal className="model-dialogadd" title="Add" open={isAddL2MolOpen}
                               onOk={() => {
                                   setAdminLevel2()
                               }}
                               onCancel={() => {
                                   setAddL2Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL2Arr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL2()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL2()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>



                        <Modal className="model-dialogadd" title="Add" open={isAddL3MolOpen}
                               onOk={() => {
                                   setAdminLevel3()
                               }}
                               onCancel={() => {
                                   setAddL3Open(false)
                               }}>
                            <Form form={form} name="control-hooks" className="form">
                                {addL3Arr.map((item, index) => {
                                    return (
                                        <Form.Item
                                            name={"address"
                                                + index
                                            }
                                            validateTrigger="onBlur"
                                            label="Address"
                                            validateFirst={true}
                                        >
                                            <div className="input-box">
                                                <Input type="text"></Input>
                                            </div>
                                        </Form.Item>
                                    )
                                })}
                                <div className="icon-box">
                                    <img width={30} src={add} onClick={() => {
                                        addOneL3()
                                    }}/>
                                    <img width={30} src={cut} onClick={() => {
                                        removeOneL3()
                                    }}/>

                                </div>
                            </Form>
                        </Modal>
                        <div>
                            <div className='panel-title'>
                                Set NFT Whitelist
                            </div>

                            <div className='superdao-list-box white-list-box'>
                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                    <div className='col'>
                                        Delete
                                    </div>
                                </div>
                                {whitelistArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display:"flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>
                                        <div className="col sc1">
                                            <img src={sc} className="sc" id='scc' style={{cursor:"pointer"}} onClick={() => {
                                                console.log(whitelistArr[index])
                                                setDelOpen(true)
                                                setCurDelAddr(whitelistArr[index])
                                            }}/>
                                        </div>

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddOpen(true)
                                    }}>Add
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div>
                            <div className='panel-title'>
                                Set L2
                            </div>
                            <div className="panel-container">
                                <div className='panel-title'>
                                   Limit Amount({L2owner})
                                </div>

                                <Form form={form} name="control-hooks" className="form">

                                    <Form.Item
                                        name="L2Amount"
                                        label="New Amounts"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Button type="primary" className="go-btn" onClick={() => {
                                        setadminL2AddWlAmount()
                                    }}>
                                        Confirm
                                    </Button>
                                </Form>
                            </div>
                            <div className='superdao-list-box white-list-box'>
                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                    {/*<div className='col'>*/}
                                    {/*    Delete*/}
                                    {/*</div>*/}
                                </div>
                                {L2AdminArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display:"flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>
                                        {/*<div className="col sc1">*/}
                                        {/*    <img src={sc} className="sc" id='scc' onClick={() => {*/}
                                        {/*        setDelOpen(true)*/}
                                        {/*        setCurDelAddr(item)*/}
                                        {/*    }}/>*/}
                                        {/*</div>*/}

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddL2Open(true)
                                    }}>Add
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div>
                            <div className='panel-title'>
                                Set L3
                            </div>
                            <div className="panel-container">
                                <div className='panel-title'>
                                    Limit Amount({L3owner})
                                </div>

                                <Form form={form} name="control-hooks" className="form">

                                    <Form.Item
                                        name="L3Amount"
                                        label="New Amounts"
                                        validateTrigger="onBlur"
                                        validateFirst={true}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Button type="primary" className="go-btn" onClick={() => {
                                        setadminL3AddWlAmount()
                                    }}>
                                        Confirm
                                    </Button>
                                </Form>
                            </div>
                            <div className='superdao-list-box white-list-box'>

                                <div className='list-header white-header'>
                                    <div className='col'>
                                        No.
                                    </div>
                                    <div className='col'>
                                        Address
                                    </div>
                                    {/*<div className='col'>*/}
                                    {/*    Delete*/}
                                    {/*</div>*/}
                                </div>
                                {L3AdminArr.map((item, index) => {
                                    return (<div className='list-item white-item' key={index} style={{display:"flex"}}>
                                        <div className='col no'>
                                            {index + 1}
                                        </div>
                                        <div className='col address'>
                                            <a>
                                                {item}
                                            </a>
                                        </div>
                                        {/*<div className="col sc1">*/}
                                        {/*    <img src={sc} className="sc" id='scc' onClick={() => {*/}
                                        {/*        setDelOpen(true)*/}
                                        {/*        setCurDelAddr(item)*/}
                                        {/*    }}/>*/}
                                        {/*</div>*/}

                                    </div>)
                                })}
                                <div className='btn-box'>
                                    <div className='addsbt' onClick={() => {
                                        setAddL3Open(true)
                                    }}>Add
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </NFTAdminStyle>
    )
}
export default NFTAdmin;